import { cn } from '@/utils/utils';

const CustomButton = ({ children, className = '', ...restProps }) => {
  return (
    <button
      className={cn(
        'select-none rounded-md bg-primary_color px-5 py-2 text-xs font-medium text-gray-100 transition-all duration-300 ease-in hover:bg-primary_color/90 disabled:opacity-50 disabled:hover:bg-primary_color lg:text-sm',
        className,
      )}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default CustomButton;
