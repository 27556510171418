'use client';

import CustomButton from '@/components/custom/CustomButton';
import Link from 'next/link';

export default function NotFound() {
  return (
    <div className="flex min-h-screen items-center justify-center gap-4">
      {/* Inline SVG for the Unlink Icon */}
      <div className="text-3xl text-gray-700">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          className="size-16 text-red-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 5.636L5.636 18.364M6 6h12v12H6V6z"
          />
        </svg>
      </div>
      <div className="border-l-2 border-gray-600 pl-4 text-3xl">
        <h2>ERROR 404</h2>
        <h2>Page Not Found</h2>

        <Link href="/">
          <CustomButton className="mt-5">Go Home</CustomButton>
        </Link>
      </div>
    </div>
  );
}
